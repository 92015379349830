import React from "react";
import {graphql, HeadProps, PageProps} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";

import '../styles/page/team/team.style.scss';
import Layout from "../components/Layout";
import {PageContextType} from "../types/page-context.type";
import {GatsbyImageCustomType} from "../types/gatsby-image.type";
import {SiteMetadata} from "../types/site-metadata.type";
import TextFormatter from "../utils/TextFormatter";
import {SiteMetadataConfigProps} from "../types/metadata-config-props.type";
import StructuredData from '../assets/structured-data';

type Member = {
  name: string,
  description: string,
  avatar: GatsbyImageCustomType | null,
}

type TeamProps = {
  strapiTeam: {
    siteMetadata: SiteMetadata,
    title: string,
    description: {
      data: {
        description: string,
      },
    },
  member: Member[]
  }
} & SiteMetadataConfigProps

const Team = ({data: {strapiTeam: {title, description, member, siteMetadata}}, pageContext}: PageProps<TeamProps, PageContextType>) => {
  const formattedDescription = TextFormatter.concatOrphans(description.data.description) || description.data.description;
  if (member.length % 4 !== 0) {
    while (member.length % 4 !== 0) {
      member.push({
        name: '',
        description: '',
        avatar: null
      })
    }
  }

  return(
    <Layout crumbs={pageContext.breadcrumb.crumbs} crumbLabel={siteMetadata.crumbLabel}>
      <section className={'flex flex-col items-center bg-white'}>
        <div className={'flex flex-col justify-center items-center text-center bg-neutrals-90 px-5 sm:px-10 xl:px-36 pt-24 w-full'}>
          <h1 className={'font-head text-4xl xl:text-5xl text-black mb-4'}>{title}</h1>
          <div className={'text-neutrals-40 text-lg mb-12 max-w-[768px]'} dangerouslySetInnerHTML={{__html: formattedDescription}}/>
        </div>
        <div className={'member-container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 shrink-0 mt-12 mb-20 gap-y-12'}>
          {member.map((m, i) => {
            if (m.avatar) {
              return(
                <div key={i} className={'member-card flex flex-col bg-white px-6 pb-6 relative w-full'}>
                  <h2 className={'font-head text-xl text-neutrals-20 font-semibold'}>{m.name}</h2>
                  <p className={'text-base text-neutrals-40 mb-2.5'}>{m.description}</p>
                  <GatsbyImage className={'rounded-b'} loading={'eager'} alt={m.avatar.alternativeText} image={m.avatar.localFile.childImageSharp.gatsbyImageData} />
                </div>
              )
            } else {
              return(
                <div key={i} className={'member-card--dummy flex flex-col bg-white px-6 relative'}>
                  <h2 className={'font-head text-xl text-neutrals-20 font-semibold'}/>
                  <p className={'text-base text-neutrals-40 mb-2.5'}/>
                  <div className={'w-full h-full'}/>
                </div>
              )
            }
          })}
        </div>
      </section>
    </Layout>
  )
}

export default Team;

export const Head = (props: HeadProps<TeamProps>) => {
  const slug = props.location.pathname;
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const {title, description, crumbLabel} = props.data.strapiTeam.siteMetadata;
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description}/>
      <meta name="robots" content="index, follow"/>
      <meta name="theme-color" content="#24B25B"/>
      {StructuredData([
        {
          name: crumbLabel,
          item: siteUrl + slug
        }
      ])}
    </>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    strapiTeam {
      title
      description {
        data {
          description
        }
      }
      siteMetadata {
        title
        description
        crumbLabel
      }
      member {
        description
        name
        avatar {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
            }
          }
        }
      }
    }
  }
`
